@import "modules/mediaqueries";
@import "helpers/variables";

html {
    background-image: url('../../images/stage-badge.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 70px;

    @include respond-to($mq-breakpoint-large) {
        background-size: inherit;
    }
}
